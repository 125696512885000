import React, { useState } from 'react';
import Slider from 'react-slick';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal'; // Nouveau composant de modale
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProjectsSection() {
  const [centeredIndex, setCenteredIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    afterChange: (index) => {
      setCenteredIndex(index);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Active le mode centré si nécessaire
        }
      }
    ]
  };
  
  return (
    <div className="py-12 bg-gray-900">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white">Quelques projets</h2>
          <p className="text-gray-400">Cette liste de projet n'est pas exhaustive, il n'est pas possible de tous les référencés pour des raisons profesionnelles. Toutefois, la majeure partie du code produit depuis mes débuts est sur GitHub, avec le mauvais code et la progression dans la pratique. Certaines compétences sont difficilement illustrable, en-effet, étant freelance j'ai aussi dû me plonger dans les pratiques de backend, en surface à minima, que ce soit pour le déploiement de mes projets, la mise en place de CI / CD, le débuggage de site eCommerce, etc. </p>
          <p className='text-gray-400'>La montée en compétence à également amener des nouveaux concepts, le travail d'une logique liée au algorithmes, la découverte de NPM et son utilisation, le travail de versionning avec les conventionnal commits et les workflows.</p>
        </div>
        <Slider {...settings}>
          {projects.map((project, index) => (
            <div key={index} className={`project-card ${centeredIndex === index ? 'zoomed' : ''}`}>
              <div className="flex items-center justify-center">
                <ProjectCard {...project} openModal={() => openModal(project)} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <ProjectModal isOpen={isModalOpen} project={selectedProject} closeModal={closeModal} />
    </div>
  );
}

export default ProjectsSection;



const projects = [
  {
    title: "Site freelance",
    description: "Site pour mes services au particulier, PME et TPE, concernant l'IT, les activités de Webmaster/Intégrateur et consultant.",
    imageUrl: require('../assets/images/dkp-website.png'),
    projectUrl: "https://dkp-consult.be",
    technos: ["HTML", "CSS", "JavaScript", "Tailwind CSS", "PHP"],
    longDescription : "Réalisation d'un site au lancement de mon activité de freelance, celuii-ci est réalisé from scratch et simple en terme de fonctionnalité. Il reprend les données légales et un formulaire de contact. Au départ, celui-ci était déployer manuellement sur le serveur, depuis j'ai découvert comment mettre en place de l'intégration continue et il est déployé à chaque push sur la branche main."
  },
  {
    title: "Blog Personnel",
    description: "Blog lié à l'apprentisage de développement ainsi que mon aventure de freelance.",
    imageUrl: require('../assets/images/blog.png'),
    projectUrl: "https://blog.dkp-consult.be",
    longDescription : "Je voulais faire un blog et en découvrir un peu plus sur NextJS, j'ai donc cherché un template de blog et commencé à trifouiller dans le code pour apprendre et réaliser un blog. La contrainte est que je ne voulais pas de CMS pour celui-ci. Pour le déployement, j'ai opté pour un déploiement continu avec Vercel, chaque push sur la branche main envoie les dernières modification en production.",
    technos: ["Next.js", "Tailwind CSS", "HTML", "JavaScript", "CSS"],
  },
  {
    title: "Advent Calandar",
    description: "Projet personnel, dans le cadre d'un évènement privé, j'ai voulu teaser celui-ci. Et mis en place ce calendrier de l'avent digital",
    imageUrl: require('../assets/images/advent-calendar.png'),
    projectUrl: "https://doc.dkp-consult.be",
    technos: ["HTML", "CSS", "JavaScript"],
    longDescription : "Projet from scratch, dans le cadre d'un évènement privé, je souhaitais teaser celui-ci. Pour ce faire, j'ai réalisé un calendrier de l'avent from scratch. Cela était aussi l'occassion pour moi de découvrir JavaScript, avant ce projet, je n'avais jamais écris de JS."
  },
  {
    title: "QCM Generator",
    description: "Un simple générateur de QCM avec image qui génère le code à intégrer sur votre site.",
    imageUrl: require('../assets/images/qcm-generator.png'),
    projectUrl: "https://labs.debski.be/qcm-generator/",
    longDescription : "Création d'un générateur de QCM qui permet de copier/coller un snippet fonctionnel. Initialement, celui-ci était prévu pour s'intégrer dans des sites WP afin que l'utilisateur final puisse créer ses propres QCM sans devoir utiliser un plugins.",
    technos: ["CSS", "JavaScript", "HTML", "Tailwind CSS"]
  }
];

