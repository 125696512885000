import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer() {
  return (
    <footer className="text-white body-font bg-gray-900">
      <div className="container px-5 py-8 mx-auto flex items-center justify-between">
        <a
          className="flex title-font font-medium items-center text-white"
          href="to-link"
        >
          <span className="text-xl">Pierre Debski</span>
        </a>

        <div className="flex justify-center items-center">
        <a href="https://www.linkedin.com/in/pierredebski" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a>
          <a href="https://twitter.com/dkp_consult" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer"><TwitterIcon /></a>
          <a href="https://github.com/dkp-consult" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer"><GitHubIcon /></a>
        </div>

        <p className="text-sm text-gray-400">
          © 2024 - <a href="https://dkp-consult.be">dkp-consult</a>
        </p>
      </div>
    </footer>
  );
}
export default Footer;
