import React, {useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import technosIcons from './TechnosIcons'; // Assure-toi que le chemin est correct

function ProjectModal({ isOpen, project, closeModal }) {

  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm" onClick={handleClickOutside}>
      <div ref={modalRef} className="w-2/4 h-3/4 bg-white bg-opacity-25 backdrop-blur-sm p-8 rounded-lg shadow-lg overflow-auto border border-white flex flex-col">
        <img src={project.imageUrl} alt={project.title} className="w-full h-2/4 object-cover rounded-t-lg" />
        <div className="mt-4 flex-1">
          <h3 className="text-3xl font-bold mb-4 text-white">{project.title}</h3>
          <p className='text-gray-300'>{project.longDescription}</p>
          <div className="flex flex-wrap justify-start items-center mt-4">
            {project.technos.map((tech, index) => (
              <div key={index} className="mr-2 pt-8">
                <FontAwesomeIcon icon={technosIcons[tech]} className="text-xl text-white" /> <span className='text-white'>{tech}</span>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-end space-x-4 mt-16">
            <a href={project.projectUrl} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-all duration-300">Site</a>
            <button onClick={closeModal} className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition-all duration-300">Fermer</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectModal;
