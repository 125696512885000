import React, { useState } from 'react';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import Pierre from "../assets/images/pierre.jpg";
import Backgound from "../assets/images/fox.webp";
import CV from "../assets/cv-2024.pdf"; // Importez votre fichier CV

const emailAddress = "pierre@debski.be";
const UserProfile = () => {
  const [darkTheme, setDarkTheme] = useState(false);

  // Fonction pour changer le thème
  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  // Fonction pour gérer le téléchargement du CV
  const downloadCV = () => {
    window.open(CV, "_blank");
  };

  return (
    <div className={`font-sans antialiased ${darkTheme ? 'text-gray-100' : 'text-gray-900'} leading-normal tracking-wider bg-cover`} style={{ backgroundImage: `url(${Backgound})` }}>
      <div className="flex items-center justify-start min-h-screen pl-10"> {/* Change justify-center to justify-start and add padding-left */}
        <div className={`w-full max-w-md rounded-lg shadow-2xl ${darkTheme ? 'bg-gray-900' : 'bg-white'} opacity-100`}>
          <div className="p-4 text-center">
            <div
              className="rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center border-2 border-solid border-white"
              style={{ backgroundImage: `url(${Pierre})` }}
            ></div>
            <h1 className="text-3xl font-bold pt-8">Pierre Debski</h1>
            <div className="mx-auto w-4/5 pt-3 border-b-2 border-green-500 opacity-25"></div>
            <p className="pt-4 text-base font-bold">Freelance - Développeur, Webmaster, Intégrateur</p>
            <p className="pt-2 text-gray-600 text-xs">Visé, Liège, Belgium</p>
            <p className="pt-8 text-sm">Pierre Debski (dkp-consult), développeur passionné et polyvalent. Banquier qui réoriente sa carrière vers le développement et la consultance IT pour y mettre à profit ma vision orientée solution et une bonne dose d’innovation. De la programmation orientée solutions pour vos projets !</p>

            <div>
              <div className="pt-12 pb-8">
                <div className="flex justify-center w-full">
                  <a href="https://www.linkedin.com/in/pierredebski" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon size={40} />
                  </a>
                  <a href="https://twitter.com/dkp_consult" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon size={40} />
                  </a>
                  <a href="https://github.com/dkp-consult" className="mr-5 hover:text-gray-400" target="_blank" rel="noopener noreferrer">
                    <GitHubIcon size={40} />
                  </a>
                </div>
                <a href={`mailto:${emailAddress}?subject=Contact depuis debski.be`}>
                  <button className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mt-4 w-full">
                    Me contacter
                  </button>
                </a>
                {/* Bouton de téléchargement du CV */}
                <button onClick={downloadCV} className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-full mt-4 w-full">
                  Télécharger mon CV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pin to top right corner */}
      <div className="absolute top-0 right-0 h-12 w-18 p-4">
        <button onClick={toggleTheme}>{darkTheme ? '☀️' : '🌙'}</button>
      </div>
    </div>
  );
};

export default UserProfile;