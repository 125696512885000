import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faJs, 
  faReact, 
  faHtml5, 
  faCss3Alt, 
  faMarkdown,
  faGit, 
  faGithub, 
  faWordpress, 
} from '@fortawesome/free-brands-svg-icons'; 
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';

function SkillsSection() {
  const skills = [
    { name: "JavaScript", icon: faJs },
    { name: "React", icon: faReact },
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "Markdown", icon: faMarkdown },
    { name: "Git", icon: faGit },
    { name: "GitHub", icon: faGithub },
    { name: "WordPress", icon: faWordpress },
    { name: "Tailwind CSS", icon: faLaptopCode }, 
    { name: "Next.js", icon: faLaptopCode }
  ];

  return (
    <div className="bg-gray-800 text-white font-sans antialiased py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-6 text-center">Compétences</h2>
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4">
          {skills.map((skill, index) => (
            <div key={index} className="flex items-center border p-4 rounded-md shadow-md bg-gray-600">
              <FontAwesomeIcon icon={skill.icon} className="text-white text-3xl mr-4" />
              <span className="text-lg">{skill.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  
}

export default SkillsSection;
