import './index.css'; // Import TailwindCSS
import React from 'react';
import Footer from './components/Footer';
import ProjectsSection from './components/ProjectsSection';
import UserProfile from './components/UserProfile';
import Skills from './components/Skills';




function App() {
  return (
    <div>
      <UserProfile />
      <ProjectsSection />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;
