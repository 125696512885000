function ProjectCard({ title, description, imageUrl, projectUrl, openModal }) {
  // Vérifier la longueur du texte de la description
  let fontSize = 'text-sm';
  if (description.length > 100) {
    fontSize = 'text-xs'; // Si la description est trop longue, réduire la taille de la police
  }

  // Vérifier si le texte est trop long pour être lisible
  const isTextTooLong = description.length > 200;

  return (
    <div onClick={() => openModal()} className="block transform transition duration-500 hover:scale-105">
      <div className="bg-white/10 backdrop-blur-sm border border-gray-200/10 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out m-10 max-w-sm" style={{ height: '350px', width: '300px' }}> 
        <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
        <div className={`p-5 h-full ${fontSize}`}>
          <h5 className="text-white text-lg font-bold mb-2">{title}</h5>
          <p className={`text-gray-300 overflow-hidden ${isTextTooLong ? 'text-ellipsis' : ''}`}>{description}</p> {/* Ajouter une classe pour le texte qui est trop long */}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;

