import { 
    faJs, 
    faReact, 
    faHtml5, 
    faCss3Alt, 
    faMarkdown,
    faGit, 
    faGithub, 
    faWordpress, 
  } from '@fortawesome/free-brands-svg-icons'; 
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';


const technosIcons = {
  "JavaScript": faJs,
  "React": faReact,
  "HTML": faHtml5,
  "CSS": faCss3Alt,
  "Markdown": faMarkdown,
  "Git": faGit,
  "GitHub": faGithub,
  "WordPress": faWordpress,
  "Tailwind CSS": faLaptopCode,
  "Next.js": faLaptopCode
};

export default technosIcons;
